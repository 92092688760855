import React from 'react'
import { CircledArrow } from '../../Icons'
import './styles.scss'

const Anchor = ({ href, children, id, target = '_blank', title }) => {
  return (
    <a id={id} href={href} target={target} className="cases__anchor" title={title}>
      {children}
      <CircledArrow />
    </a>
  )
}

export default Anchor
