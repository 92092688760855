import React from "react"
import SectionContainer from "../../SectionContainer"

import "./styles.scss"

const Citation = ({ children, className }) => {
  return (
    <SectionContainer className="citation">
      <div className={`citation__paragraph-container ${className}`}>
        {children}
      </div>
    </SectionContainer>
  )
}

export default Citation
