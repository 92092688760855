import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import useStaticImage from "../../../../hooks/useStaticImage"
import SectionContainer from "../../SectionContainer"
import "./styles.scss"

export const ImageBlock = ({ alt, noCaption, image, ...props }) => {
  const [getImage] = useStaticImage()

  return (
    <SectionContainer className="image-block" {...props}>
      <GatsbyImage
        image={getImage(image)}
        className="image-block__image"
        alt={alt}
      />
      {!noCaption && <span>{alt}</span>}
    </SectionContainer>
  )
}

export default ImageBlock
