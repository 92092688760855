import React from "react"
import SectionContainer from "../../SectionContainer"
import "./styles.scss"

const Introduction = ({ Title, children }) => {
  return (
    <SectionContainer className="introduction">
      <h1>
        <Title />
      </h1>
      {children && <h2>{children}</h2>}
    </SectionContainer>
  )
}

export default Introduction
