import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Anchor,
  ClientSolutionAndSummary,
  ImageBlock,
  Introduction,
  TextBlock,
} from '../../../components/molecules/Cases'
import { TechLead, QA, WebPage } from '../../../components/molecules/Icons'
import SectionContainer from '../../../components/molecules/SectionContainer'
import useStaticImage from '../../../hooks/useStaticImage'
import content from './content.json'
import './styles.scss'

const { clientSolutionAndSummary } = content

const Content = () => {
  const [getImage] = useStaticImage()

  const Intro = () => {
    return (
      <>
        <Introduction Title={() => <b>Cantareira Seca</b>}>
          Saiba como priorizar a experiência do usuário pode transformar a interação com o
          site, unindo educação e leveza
        </Introduction>

        <ClientSolutionAndSummary
          client={clientSolutionAndSummary.client}
          solutions={clientSolutionAndSummary.solutions}
          brand={clientSolutionAndSummary.brand}
          summary={clientSolutionAndSummary.summary}
        />

        <ImageBlock
          image="cantareira-note-1.webp"
          alt="Página inicial | Reprodução site"
        />
      </>
    )
  }

  const GetToKnowUs = () => {
    return (
      <SectionContainer className="cantareira__get-to-know-us">
        <GatsbyImage
          image={getImage('cantareira-phone.webp')}
          alt="Tela mobile - Cliente Cantareira Seca"
        />

        <div className="cantareira__get-to-know-us__text-block">
          <h3 id="conheca" className="cantareira__subtitle">
            Conheça a Cantareira Seca
          </h3>

          <p>
            É possível unir conscientização e bom humor para divulgar uma mensagem
            importante ao público? Segundo o case Cantareira Seca, a resposta é sim!
            <br />
            <br />O projeto de desenvolvimento do site nasceu com o{' '}
            <b>
              propósito de alertar a população de São Paulo, especialmente aquelas que
              residem próximas à Cantareira, sobre a seca no Sistema.
            </b>{' '}
            Além disso, também possui a missão de tornar a população mais ativa e
            ecologicamente correta ao trazer dicas sobre como economizar água.
          </p>
        </div>
      </SectionContainer>
    )
  }

  const Advantages = () => {
    const levels = [
      {
        Title: () => (
          <>
            <b>01. Níveis bons de água:</b> a personagem será simpática com o usuário
          </>
        ),
        image: 'cantareira-3.webp',
      },
      {
        Title: () => (
          <>
            <b>02. Níveis médios: </b>ela entra em estado de alerta e diz ao leitor que “o
            bicho irá pegar”
          </>
        ),
        image: 'cantareira-2.webp',
      },
      {
        Title: () => (
          <>
            <b>03. Níveis baixos:</b> o usuário irá encontrar a versão mais “grossa” da
            personagem, chegando a encontrar palavrões nas mensagens
          </>
        ),
        image: 'cantareira-1.webp',
      },
    ]

    return (
      <div id="diferenciais">
        <TextBlock title="Quais são os diferenciais do projeto?">
          Considerando que o contexto atual do ambiente on-line aponta para uma relevância
          cada vez maior do User Experience (UX), a Cantareira Seca se destaca por
          promover uma interação imersiva.{' '}
          <b>
            Ou seja, o usuário poderá encontrar três tipos diferentes de conteúdos no
            site, baseados de acordo com o nível da Cantareira.
          </b>
          <br />
          <br />
          Em outras palavras, significa que o “humor” da personagem do site estará
          diretamente ligado aos registros da Sabesp:
        </TextBlock>
        <SectionContainer>
          {levels.map(({ Title, image }) => (
            <div className="cantareira__levels">
              <h4>
                <Title />
              </h4>

              <GatsbyImage image={getImage(image)} />
              <span>Reprodução site</span>
            </div>
          ))}
        </SectionContainer>
      </div>
    )
  }

  const ProjectSteps = () => {
    return (
      <>
        <SectionContainer className="cantareira__project-steps">
          <div className="cantareira__project-steps__column">
            <h3 id="etapas" className="cantareira__subtitle">
              Quais foram as etapas do projeto?
            </h3>
            <p>
              Visando uma <b>entrega ágil e respeitando os prazos do cliente,</b> foram
              cerca de dois meses entre a reunião de briefing, etapa inicial, até a
              entrega do site finalizado. Ao longo do caminho, foram realizados alguns
              checkpoints sobre o andamento do projeto.
            </p>

            <Anchor href="/services/projects" target="_self" title="Serviços">
              A DR ajuda você a se destacar no ambiente digital
            </Anchor>
          </div>

          <div className="cantareira__project-steps__column">
            <h3 id="talentos" className="cantareira__subtitle">
              Quais são os talentos da DR_ que atuaram no desenvolvimento do site?
            </h3>

            <p>Time SPOT:</p>

            <div>
              <div>
                <WebPage />
                <span>Desenvolvedor Full-Stack</span>
              </div>

              <div>
                <QA />
                <span>Quality Assurance (QA)</span>
              </div>

              <div>
                <TechLead />
                <span>Tech Lead</span>
              </div>
            </div>
          </div>
        </SectionContainer>

        <ImageBlock
          image="cantareira-note-2.webp"
          alt="Página inicial | Reprodução site"
        />
      </>
    )
  }

  const GetToKnow = () => {
    return (
      <SectionContainer className="cantareira__get-to-know">
        <p>No mundo digital, priorizar a experiência do usuário é muito importante.</p>

        <Anchor target="_blank" href="https://cantareiraseca.com.br/">
          Conheça o site da Cantareira Seca agora!
        </Anchor>
      </SectionContainer>
    )
  }

  const Technologies = () => {
    return (
      <>
        <TextBlock
          title="Quais tecnologias ajudaram a construir esses diferenciais?"
          hasMargin
        >
          <b>Gatsby, React e Firebase</b> foram as escolhas do nosso time técnico para
          executar o projeto. Considerando que o site prioriza os aspectos interativo e
          visual, também destacamos as <b>animações com Parallax.</b>
        </TextBlock>
        <div id="relevancia">
          <TextBlock
            title="Qual é a relevância da Cantareira Seca para o público?"
            hasMargin
          >
            O desenvolvimento do site{' '}
            <b>
              apresenta uma forma leve e interativa de educar o público sobre o Sistema
              Cantareira,
            </b>{' '}
            o principal produtor de água da Região Metropolitana de São Paulo. Ele é
            composto por cinco reservatórios e abastece cerca de 46% da população que
            habita a RMSP.
            <br />
            <br />A atenção ao Sistema foi redobrada após o período de seca na Região
            Sudeste brasileira que ocorreu em meados de 2014 e levou os reservatórios a
            não receberem o volume de água esperado. Por esse motivo, ações que estimulem
            a população a contribuir com o consumo consciente de água são fundamentais.
          </TextBlock>
        </div>
      </>
    )
  }

  return (
    <>
      <Intro />
      <GetToKnowUs />
      <Advantages />
      <ProjectSteps />
      <GetToKnow />
      <Technologies />
    </>
  )
}

export default Content
