import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import SectionContainer from "../../SectionContainer"
import useStaticImage from "../../../../hooks/useStaticImage"
import "./styles.scss"

export const MultipleImageBlock = ({ images, alt, className, ...props }) => {
  const [getImage] = useStaticImage()

  return (
    <SectionContainer
      className={`multiple-image-block ${className ? className : ""}`}
      {...props}
    >
      <div>
        {images.map(image => (
          <GatsbyImage
            className="multiple-image-block__image__wrapper"
            image={getImage(image)}
            alt={alt}
          />
        ))}
      </div>
      <span>{alt}</span>
    </SectionContainer>
  )
}

export default MultipleImageBlock
