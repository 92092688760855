import React from "react"
import SectionContainer from "../../SectionContainer"
import "./styles.scss"

const TextBlock = ({ title, children, hasMargin, className, ...props }) => {
  return (
    <SectionContainer
      className={`text-block ${hasMargin ? "text-block--margin" : ""} ${
        className && className
      }`}
      {...props}
    >
      <h3>{title}</h3>
      <p>{children}</p>
    </SectionContainer>
  )
}

export default TextBlock
