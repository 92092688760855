export const anchorToElement = (event) => {
  const isMounted = typeof window !== "undefined"

  if (isMounted) {
    const header = document.querySelector(".header")
    const headerHeight = header.offsetHeight
    const target = document.querySelector(event.target ? `#${event.target.dataset.section}` : event)
    const targetPosition = target.getBoundingClientRect().top - headerHeight

    window.scrollTo({
      top: targetPosition + window.pageYOffset,
      behavior: "smooth",
    })
  }
}
